import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Injector, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Observable, throwError, EmptyError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../ms/oauth2/service/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  refreshTokenInProgress = false;

  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  addAuthHeader(request) {
    // add auth header with jwt if user is logged in and request is to api url
    // TODO change "/api" to check by ms path
    const isApiUrl = request.url.includes('/api');
    if (this.authenticationService.isLoggedIn() && isApiUrl) {
      // console.log("message URL", request.url);
      // console.log("Token injecté", this.authenticationService.currentUserValue.accessToken.access_token);
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authenticationService.currentUserValue.accessToken.access_token}`,
        },
      });
    }
    return request;
  }

  refreshToken(): Observable<any> {
    console.log('refresh: ', this.refreshTokenInProgress);
    if (this.refreshTokenInProgress) {
      return new Observable((observer) => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return this.authenticationService.refreshToken().pipe(
        tap(() => {
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next();
        }),
        catchError(() => {
          this.refreshTokenInProgress = false;
          this.logout();
          return EmptyError;
        })
      );
    }
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['login']);
  }

  handleResponseError(error, request?, next?) {
    // Business error
    if (error.status === 400) {
      // Show message
      console.log('Bad request:', error);
    }

    // Invalid token error
    else if (error.status === 401) {
      console.log('Invalid token error:', error);

      return this.refreshToken().pipe(
        switchMap(() => {
          console.log('Refreshing token after invalid token error !');
          request = this.addAuthHeader(request);
          return next.handle(request);
        }),
        catchError((e) => {
          if (e.status !== 401) {
            return this.handleResponseError(e);
          } else {
            console.log('Need to logout after refresh token request failed !');
            this.logout();
          }
        })
      );
    }

    // Access denied error
    else if (error.status === 403) {
      // Show message
      // Logout
      console.log('Error: ', error.statusText);
      this.router.navigate(['/hospital/hosp-dashboard']);
    }

    // Server error
    else if (error.status === 500) {
      // Show message
      console.log('Error:', error.statusText);
    }

    // Maintenance error
    else if (error.status === 503) {
      // Show message
      // Redirect to the maintenance page
      console.log('Error:', error.statusText);
    }

    return throwError(error);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // Handle request
    request = this.addAuthHeader(request);

    // Handle response
    return next.handle(request).pipe(
      catchError((error) => {
        return this.handleResponseError(error, request, next);
      })
    );
  }
}

export const AuthInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};
