import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";

import { User } from "../ms/oauth2/model/User";
import { AuthenticationService } from "../ms/oauth2/service/authentication.service";

@Injectable({
  providedIn: "root",
})
export class AuthorizationGuard implements CanActivate, CanActivateChild {
  /**
   *
   */
  constructor(
    public router: Router,
    private authenticationService: AuthenticationService
  ) {}

  /**
   *
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // this will be passed from the route config
    // on the data property

    if (this.authenticationService.currentUserValue) {
      if (!this.authenticationService.isExpired()) {
        console.log("current user value" + this.authenticationService.currentUserValue);
        return this.checkAuth(
          this.authenticationService.currentUserValue,
          route
        );
      } else {
        console.log("token expired in authorization guard");
        this.authenticationService.refreshToken().subscribe(
          (data) => {
            // console.log("lien",this.getConfiguredUrl(route))
            // this.router.navigate([this.getConfiguredUrl(route)])
            window.location.reload();
          },
          (error) => {
            console.log(error);
          }
        );
      }
    } else {
      // not logged in so redirect to login page with the return url
      //this.router.navigate(["/login"], {queryParams: { returnUrl: state.url },});
      this.router.navigate(["/login"]);
      return false;
    }
  }
  getConfiguredUrl(route: ActivatedRouteSnapshot): string {
    
    return route.pathFromRoot
        .filter(v => v.routeConfig)
        .map(v => v.routeConfig!.path)
        .join('/');
}

  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    console.log("pathFromRoot", route.pathFromRoot)
    
    return route.pathFromRoot
    .map(v => v.url.map(segment => segment.toString()).join('/'))
       .join('/');
}

  currentUserIsAuthorized(userRoles, necessaryRoles) {
    return userRoles.some((r) => necessaryRoles?.indexOf(r) >= 0);
  }

  checkAuth(currentUser: User, route: ActivatedRouteSnapshot): boolean {
    // const expectedAuth = route.data.expectedAuth;
    // check if route is restricted by role

    if (
      route.data &&
      route.data.roles &&
      currentUser &&
      currentUser.roles &&
      !this.currentUserIsAuthorized(currentUser.roles, route.data.roles)
    ) {
      // role not authorized so redirect to home page
      this.router.navigate(["/hospital/hosp-dashboard"]);
      return false;
    } else {
      // authorized so return true
      return true;
    }
  }

  /**
   *
   */
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }
}
