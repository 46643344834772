import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BaseChartDirective } from 'angular-bootstrap-md';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss'],
})
export class DoughnutChartComponent implements OnInit {
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  @Input() page: string;
  @Input() labels: string[];
  @Input() data: any[];
  @Input() translable: boolean;
  @Input() clickable: boolean;

  chartReady: boolean = false;

  public chartLabels: Array<any>;

  public chartDatasets: Array<any>;

  constructor(private router: Router, private translate: TranslateService) {}

  ngOnInit(): void {
    console.log('chart data',this.data);
    this.chartDatasets = [
      {
        data: [...this.data],
      },
    ];
    //console.log(this.chartDatasets)
    this.chartReady = true;

    /* this.translable
      ? (this.chartLabels = [
          ...this.labels.map((label) =>
            this.translate.instant(`${this.page}.${label}`)
          ),
        ])
      : (this.chartLabels = [...this.labels]); */
    this.chartLabels = [...this.labels];
    //console.log("labels: ", this.chartLabels);
  }

  public chartType: string = 'doughnut';

  public chartColors: Array<any> = [
    {
      backgroundColor: ['#46BFBD', '#FDB45C', '#949FB1', '#4D5360', '#F7464A'],
      hoverBackgroundColor: [
        '#5AD3D1',
        '#FFC870',
        '#A8B3C5',
        '#616774',
        '#FF5A5E',
      ],
      borderWidth: 2,
    },
  ];

  public chartOptions: any = {
    responsive: true,
    showLabel: true,
    legend: {
      position: 'right',
    },
  };
  public chartClicked(e: any): void {
    //console.log(e.active[0]._index);
    if (this.clickable) {
      if (e.active[0] != null) {
        if (e.active[0]._index == 0)
          this.router.navigate(['/search', 'sup_6m']);
        if (e.active[0]._index == 1)
          this.router.navigate(['/search', 'inf_5s']);
        if (e.active[0]._index == 2)
          this.router.navigate(['/search', 'bet_3m_6m']);
        if (e.active[0]._index == 3)
          this.router.navigate(['/search', 'bet_5s_3m']);
        if (e.active[0]._index == 4) this.router.navigate(['/search', 'ic']);
      }
    }
  }
  public chartHovered(e: any): void {}
}
